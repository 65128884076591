import React, { useEffect, useState } from 'react';
// IMPORTACIONES PARA TABLE MUI
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
// IMPORTACIONES PARA EL FEEDBACK
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TextField, IconButton } from '@mui/material';

const productosMockup = [
  { nombre: 'Producto A', um: 'kg', cantidad: 10 },
  { nombre: 'Producto B', um: 'ltr', cantidad: 5 },
  { nombre: 'Producto C', um: 'pz', cantidad: 20 },
];

// CONFIGURACION DE FEEDBACK
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export const CreateOperacionDevolucionV2 = () => {
  const [fechaActual, setFechaActual] = useState('');
  const [productos, setProductos] = useState(productosMockup);
  const [modoEdicion, setModoEdicion] = useState(null);

  useEffect(() => {
    const fecha = new Date();
    const fechaFormateada = fecha.toISOString().slice(0, 16);
    setFechaActual(fechaFormateada);
  }, []);
  const handleEditClick = (index) => {
    setModoEdicion(index);
  };

  const handleSaveClick = (index) => {
    setModoEdicion(null);
  };

  const handleCantidadChange = (index, valor) => {
    const nuevosProductos = [...productos];
    nuevosProductos[index].cantidad = valor;
    setProductos(nuevosProductos);
  };
  const handleDeleteClick = (index) => {
    const nuevosProductos = productos.filter((_, i) => i !== index);
    setProductos(nuevosProductos);
  };
  const [files, setFiles] = useState([])

  // Manejar el cambio de archivos
  const handleFileChange = (event) => {
    // Obtener los archivos seleccionados
    const newFiles = Array.from(event.target.files)
    // Combinar los archivos anteriores con los nuevos
    setFiles((prevFiles) => [...prevFiles, ...newFiles])
  }


  return (
    <>
    {/* hay que consultar si es necesario de cambiar el formulario que se esta proponiendo */}
      <div className="container mx-auto mt-6" style={{ maxWidth: '800px' }}>
        <h1 className="text-center">Formulario de devolución de productos</h1>

        {/* DATOS DE LA REQUISICION */}
        <div className="row mt-4">
          <div className="card">
            <h6 className="card-header">Datos Generales</h6>
            <div className="card-body">
              <div className="mb-3 row">
                <div className="col-md-3">
                  <label htmlFor="nombre" className="form-label" >
                    N° de Comprobante
                  </label>
                  <input
                    type="text"
                    name="codLotProd"
                    className="form-control"
                  />
                </div>
                <div className="col-md-1">
                  <button
                    title='Buscar'
                    type="sumit"
                    name="codLotProd"
                    className="form-control btn btn-primary"
                    style={{ marginTop: '30px' }}
                  >
                    ?
                  </button>
                </div>
                <div className="col-md-8">
                  {/* en esta se debe de poder buscar en base al documento o nombre de cliente */}
                  <label htmlFor="nombre" className="form-label" >
                    Cliente
                  </label>
                  <input
                    type="text"
                    name="codLotProd"
                    className="form-control"
                    readOnly
                  />
                </div>
              </div>
              <div className="mb-3 row d-flex align-items-center">
                <div className="col-md-4">
                  <label htmlFor="nombre" className="form-label">
                    Telefono
                  </label>
                  <input type="number" className="form-control" />
                </div>
                <div className="col-md-8 me-8">
                  <label htmlFor="nombre" className="form-label">
                    Fecha de reclamo
                  </label>
                  <br />
                  <input
                    type="datetime-local"
                    className="form-control"
                    value={fechaActual}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="card">
            <h6 className="card-header">
              Detalle del reclamo
            </h6>
            <div className="card-body">
              <form className="row mb-4 mt-4">
                {/* AGREGAR MATERIA PRIMA */}
                <div className="col-md-12">
                  <div className="mb-3 row">
                    <label className="col-md-12 col-form-label"><b>Seleccione los productos observados e ingrese la cantidad:</b>(Descarte los productos no observados)</label>
                    <div className="col-md-12">
                      <div className="card-body">
                        <Paper>
                          <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                              <TableHead>
                                <TableRow
                                  sx={{
                                    '& th': {
                                      color: 'rgba(96, 96, 96)',
                                      backgroundColor: '#f5f5f5'
                                    }
                                  }}
                                >
                                  <TableCell align="left" width={280}>
                                    <b>Nombre</b>
                                  </TableCell>
                                  <TableCell align="left" width={20}>
                                    <b>U.M</b>
                                  </TableCell>
                                  <TableCell align="left" width={120}>
                                    <b>Cantidad</b>
                                  </TableCell>
                                  <TableCell align="left" width={120}>
                                    <b>Acciones</b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {productos.map((producto, index) => (
                                  <TableRow key={index}>
                                    <TableCell align="left">{producto.nombre}</TableCell>
                                    <TableCell align="left">{producto.um}</TableCell>
                                    <TableCell align="left">
                                      {modoEdicion === index ? (
                                        <TextField
                                          type="number"
                                          value={producto.cantidad}
                                          onChange={(e) => handleCantidadChange(index, e.target.value)}
                                          inputProps={{ min: 0 }}
                                        />
                                      ) : (
                                        producto.cantidad
                                      )}
                                    </TableCell>
                                    <TableCell align="left">
                                      {modoEdicion === index ? (
                                        <IconButton onClick={() => handleSaveClick(index)}>
                                          <CheckIcon />
                                        </IconButton>
                                      ) : (
                                        <IconButton onClick={() => handleEditClick(index)}>
                                          <EditIcon />
                                        </IconButton>
                                      )}
                                      <IconButton onClick={() => handleDeleteClick(index)}>
                                        <DeleteIcon />
                                      </IconButton>
                                      {/* {modoEdicion === index ? (
                                        <button onClick={() => handleSaveClick(index)}><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-caret-up-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                      </svg></button>
                                        // <IconButton onClick={() => handleSaveClick(index)}>
                                        //   <CheckIcon />
                                        // </IconButton>
                                      ) : (
                                        <button onClick={() => handleEditClick(index)}><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-pencil-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                      </svg></button>
                                      )} */}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </div>
                    </div>
                  </div>
                </div>

                {/* AGREGAR DETALLES DEL RECLAMO */}
                <div className="col-md-12">
                  <div className="mb-3 row">
                    <label className="col-md-12 col-form-label"><b>Ingrese el detalle u observación:</b></label>
                    <div className="col-md-12">
                      <textarea
                        type="select"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                {/* AGREGAR IMAGENES DEL PRODUCTO */}
                <div className="col-md-12">
                  <div className="mb-3 row">
                    <label className="col-md-12 col-form-label"><b>Evidencias del reclamo:</b></label>
                    <div className="col-md-12">
                      <input
                        type="file"
                        className="form-control"
                        multiple // Permite la selección de varios archivos
                        onChange={handleFileChange} // Maneja el cambio de archivos
                      />
                    </div>
                  </div>
                  {/* Mostrar los nombres de los archivos seleccionados */}
                  {files.length > 0 && (
                    <div className="mt-3">
                      <h5>Archivos seleccionados:</h5>
                      <ul>
                        {files.map((file, index) => (
                          <li key={index}>{file.name}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                {/* BOTON AGREGAR MATERIA PRIMA */}
                {/* <div className="col-md-12 d-flex justify-content-end">
                  <button className="btn btn-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-circle-fill me-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                    </svg>
                    Agregar
                  </button>
                </div> */}
              </form>
              <div className="btn-toolbar mt-4 d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  onClick={() => window.close()}
                  style={{ width: '100px' }}
                >
                  Volver
                </button>

                <button type="submit" className="btn btn-primary" style={{ width: '100px' }}>
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* BOTONES DE CANCELAR Y GUARDAR */}

      </div>



    </>
  )
}
