import React from 'react'
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  Image
} from '@react-pdf/renderer'
import logo from '../emaran.png'
import { DetalleOrden } from './DetalleOrden'
import { stylesPDF } from './stylePDF'

const styles = stylesPDF

export const PDFExample = ({ result }) => {
  const now = new Date();
  const formattedDate = now.toLocaleDateString(); // Formateo de fecha (ej. "9/16/2024")
  const formattedTime = now.toLocaleTimeString(); // Formateo de hora (ej. "10:35:00 AM")
  return (
    <PDFViewer width="100%" height="100%">
      <Document>
        <Page
          size="A4"
          style={{
            ...styles.page,
            marginTop: 20,
            paddingTop: 20,
            paddingBottom: 40
          }}
        >
          <View style={styles.section}>
            <View style={styles.container}>
              <Image
                src={logo}
                style={{ ...styles.logo, marginTop: -105, marginLeft: 20 }}
              />
            </View>

            <View style={{ ...styles.row, marginTop: -10 }}>
              <View style={styles.column}>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >
                  Nombre del producto: {result.nomProd}
                </Text>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >
                  Nombre del proveedor: {result.nomProv}
                </Text>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >
                  Guia de remisión: {result.guiRem}
                </Text>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >
                  Fecha de Ingreso: {result.fecEntSto}
                </Text>
                ,
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >
                  Fecha de Revisión: {result.informacion_calidad.fecActEntCal}
                </Text>,
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >
                  Condiciones de higiene del transporte: {result.informacion_calidad.conHigTrans}
                </Text>
                
              </View>

              <View style={{ ...styles.row, marginTop: -40 }}>
                <View style={styles.column}>
                  <Text
                    style={{
                      ...styles.content,
                      fontWeight: 'bold',
                      borderRadius: 5,
                      fontSize: 16,
                      marginBottom: 1,
                      backgroundColor: '#d8dbe3',
                      padding: 5,
                      marginRight: 20
                    }}
                  >
                    ENTRADA STOCK CALIDAD
                  </Text>
                  <View
                    style={{
                      ...styles.row,
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '250px'
                    }}
                  >
                    <Text
                      style={{
                        ...styles.gridContent,
                        marginLeft: 0,
                        marginTop: 10,
                        width: '100%',
                      }}
                    >
                      {/* ACA TIENE QUE IR EL CODIGO DE ENTRADAD DEL DE ENTRADA STOCK */}
                      {result.codEntSto}
                    </Text>
                  </View>

                  <View
                    style={{
                      ...styles.sectionWithBorder,
                      marginTop: 10,
                      backgroundColor: '#d8dbe3',
                      width: 236,
                      height: 70,
                      borderRadius: 5,
                      marginRight: 20
                    }}
                  >
                    <Text
                      style={{
                        ...styles.content,
                        marginLeft: 10,
                        marginTop: 7,
                        maxWidth: '100%'
                      }}
                    >
                      Codigo EMAPROD: {result.codProd2}
                    </Text>

                    <Text
                      style={{
                        ...styles.content,
                        marginLeft: 10,
                        marginTop: 4,
                        maxWidth: '100%'
                      }}
                    >
                      Cantidad Total: {result.canTotCom}
                    </Text>
                    <Text
                      style={{
                        ...styles.content,
                        marginLeft: 10,
                        marginTop: 4
                      }}
                    >
                      Cantidad Disponible: 
                      {result.canTotDis}
                      {/* {parseFloat(result.canTotDis).toFixed(2)} */}
                    </Text>

                    <Text
                      style={{
                        ...styles.content,
                        marginLeft: 10,
                        marginTop: 4,
                        maxWidth: '100%'
                      }}
                    >
                      Cantidad seleccionada:{' '}
                      {/* {parseFloat(
                        result.produccion.klgTotalLoteProduccion
                      ).toFixed(2)} */}
                    </Text>
                  </View>

                  <Text
                    style={{
                      ...styles.content,
                      marginLeft: 130,
                      marginTop: -10,
                      maxWidth: '100%',
                      fontSize: 5
                    }}
                  >
                   Fecha de creación del reporte: {formattedDate} {/*  {formattedTime} */}
                  </Text>
                </View>
              </View>
            </View>

            {<DetalleOrden result={result} />}
            <View style={{ ...styles.row, flexDirection: 'row', marginTop: 10 }}>
              <View style={styles.column}>
                <Text
                  style={{
                    ...styles.content,
                    fontSize: 9,
                    minWidth: '60%',
                    marginBottom: 2,
                    marginTop: 2,
                    marginLeft: 20
                  }}
                >
                  Observaciones
                </Text>
                <View
                  style={{
                    padding: 1,
                    fontWeight: 'bold',
                    maxWidth: '90%',
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: '#000',
                    height: 50,
                    marginTop: 2,
                    marginLeft: 20
                  }}
                >
                  <Text
                    style={{
                      ...styles.content,
                      fontSize: 9,
                      marginLeft: 10,
                      marginRight: 0,
                      paddingRight: 0,
                      inlineSize: '50px',
                      overflowWrap: 'break-word',
                      maxWidth: 275,
                      maxHeight: 275
                    }}
                  >
                    {result.informacion_calidad.obsAccEntCal}
                  </Text>
                </View>
              </View>

              <View style={[styles.column, { marginRight: 20 }]}>
                <Text
                  style={{
                    ...styles.content,
                    fontSize: 9,
                    maxWidth: '30%',
                    marginBottom: 2,
                    marginTop: 2,
                    marginLeft: 0,
                  }}
                >
                  Firma Aprobación
                </Text>
                <View
                  style={{
                    padding: 1,
                    fontWeight: 'bold',
                    maxWidth: '100%',
                    borderRadius: 1,
                    borderWidth: 1,
                    borderColor: '#000',
                    height: 50, // Ajusta la altura para acomodar la línea divisoria
                    marginTop: 2,
                    marginLeft: 0,
                    justifyContent: 'space-between', // Asegura que el contenido esté distribuido
                  }}
                >
                  {/* Primera parte del contenido */}
                  <Text
                    style={{
                      ...styles.content,
                      fontSize: 9,
                      marginLeft: 10,
                      marginRight: 0,
                      paddingRight: 0,
                    }}
                  >
                    {/* Contenido superior */}
                  </Text>

                  {/* Línea divisoria */}
                  <View
                    style={{
                      height: 1, // Altura pequeña para la línea
                      backgroundColor: '#000', // Color de la línea
                      marginVertical: 5, // Espaciado alrededor de la línea
                    }}
                  />

                  {/* Segunda parte del contenido */}
                  <Text
                    style={{
                      ...styles.content,
                      fontSize: 9,
                      marginLeft: 40,
                      marginRight: 0,
                      marginTop: -20,
                      paddingRight: 0,
                      textAlign: 'center', // Centra el contenido del texto horizontalmente
                    }}
                  >
                    {result.informacion_calidad.nomEncCal}
                    {/* Contenido inferior */}
                  </Text>

                </View>

              </View>
            </View>

          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}
