import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { TextField, MenuItem, Menu } from '@mui/material'
import FechaPicker from '../../../src/components/Fechas/FechaPicker'
import FechaHoraPicker from '../../../src/components/Fechas/FechaHoraPicker'
import FechaPickerYear from '../../../src/components/Fechas/FechaPickerYear'
export const RowProductosDisponiblesProduccion = ({
  detalle,
  onChangeDetalle
}) => {
  return (
    <TableRow
      key={detalle.id}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 }
      }}
    >
      <TableCell component="th" scope="detalle">
        {detalle.nomProd}
      </TableCell>
      <TableCell align="left">{detalle.simMed}</TableCell>
      <TableCell align="left">{detalle.desCla}</TableCell>
      <TableCell align="left">
        <FechaPicker
          onNewfecEntSto={(data) => {
            const event = {
              target: {
                value: data,
                name: 'fecEntSto'
              }
            }
            onChangeDetalle(event, detalle.idProdt);
          }}
          date={detalle.fecEntSto}
        />
      </TableCell>
      <TableCell align="left">
        <FechaPickerYear
          onNewfecEntSto={(data) => {
            const event = {
              target: {
                value: data,
                name: 'fecVenEntProdFin'
              }
            }
            onChangeDetalle(event, detalle.idProdt);
          }}
          date={detalle.fecVenEntProdFin}
        />
      </TableCell>
      <TableCell align="left">
        <TextField
          type="number"
          autoComplete="off"
          size="small"
          value={detalle.canProdFin}
          name="canProdFin"
          onChange={(e) => {
            onChangeDetalle(e, detalle.idProdt);
          }}
        />
      </TableCell>
      {/* <TableCell align="left">
        <TextField
          type="number"
          autoComplete="off"
          size="small"
          value={detalle.canPerso || 1}
          name="canPerso"
          onChange={(e) => {
            onChangeDetalle(e, detalle.idProdt);
          }}
        />
      </TableCell> */}
      <TableCell align="left">
        <TextField
          select
          fullWidth
          variant="outlined"
          size="small"
          style={{ width: 150 }}
          label="Seleccione tipo ingreso"
          value={detalle.idTipoEntra || ''} // Mantiene el valor seleccionado en el select
          onChange={(e) => {
            const event = {
              target: {
                value: e.target.value,  // Valor seleccionado
                name: 'idTipoEntra'     // Nombre del campo
              }
            };
            onChangeDetalle(event, detalle.idProdt); // Envía el valor seleccionado
          }}
        >
          <MenuItem value="">
            <em>Seleccione tipo de ingreso</em>
          </MenuItem>
          <MenuItem value="1">Ingreso parcial</MenuItem>
          <MenuItem value="2">Ingreso total</MenuItem>
        </TextField>
      </TableCell>
    </TableRow>


  )
}
